import { json, type LoaderFunction, type MetaFunction } from '@remix-run/node';
import { Link, useLoaderData } from '@remix-run/react';
import { authenticator } from '~/services/auth.server';
import { MainLayout } from '~/components/layouts/main-layout';
import { findAllIntegrations } from '~/integrations';
import { Integration } from '~/integrations/types';
import { Button } from '~/components/ui/button';

export const meta: MetaFunction = () => {
  return [
    { title: 'Sidekick' },
    {
      name: 'description',
      content: 'Effortless email responses with AI-powered automation.',
    },
  ];
};

// loader function that loads data from markdown files
export const loader: LoaderFunction = async ({ request }) => {
  const user = await authenticator.isAuthenticated(request);
  const integrations: Integration[] = findAllIntegrations();

  return json({ user, integrations });
};

export default function Index() {
  const { user, integrations } = useLoaderData<typeof loader>();
  return (
    <MainLayout user={user}>
      <h1 className="text-3xl font-bold leading-tight tracking-tighter mb-4">
        Connect AI with Your Favorite Apps.
      </h1>
      <p className="max-w-2xl text-lg font-extralight text-foreground">
        Unlock the full potential of your workflows by seamlessly connecting AI
        to Slack, Gmail, Google Docs, Calendar, and more. Automate tasks, boost
        productivity, and let AI handle the heavy lifting so you can focus on
        what matters most. One integration, endless possibilities.
      </p>

      <div className="mt-4">
        <h2 className="text-2xl font-bold leading-tight tracking-tighter mb-4">
          How it works?
        </h2>
        <div>
          <ul>
            <li>Sign up to create account</li>
            <li>Connect Sidekick to to your Gmail, Calendar and Slack</li>
            <li>
              Start talking to your integrated applications via your favourite
              chat app
            </li>
          </ul>
        </div>
      </div>
      {user ? (
        <div className="mb-4 mt-4">
          <Link to="/dashboard">
            <Button>Go to Dashboard</Button>
          </Link>
        </div>
      ) : null}
      <div className="mt-4">
        <h2 className="text-xl font-bold leading-tight tracking-tighter mb-4">
          Available integrations
        </h2>
        <div className="flex">
          {integrations.map((integration: Integration) => (
            <div key={integration.id} className="p-4 border mr-2 rounded">
              <img
                className="h-12 w-12"
                src={`data:image/svg+xml;base64,${integration.icon}`}
                alt={integration.name}
              />
            </div>
          ))}
        </div>
      </div>
    </MainLayout>
  );
}
